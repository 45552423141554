import { cn } from '@/lib/utils';
import { Icons } from '../icons';

interface LoaderProps {
  className?: string;
  message?: string;
}

function Loader({ className, message = 'Laddar...' }: LoaderProps) {
  return (
    <div
      className={cn(
        'my-10 flex w-full flex-col items-center justify-center gap-4',
        className
      )}
    >
      <Icons.loader
        className='animate-spin text-foreground'
        width={34}
        height={34}
      />
      <span className='text-sm font-medium'>{message}</span>
    </div>
  );
}

export { Loader };
