'use client';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { createCalendar } from '@internationalized/date';
import { useRef } from 'react';
import type { AriaDatePickerProps, DateValue } from 'react-aria';
import { useDateField, useLocale } from 'react-aria';
import { useDateFieldState } from 'react-stately';
import { DateSegment } from './date-segment';

export type AdditionalDateFieldProps = {
  hideResetButton?: boolean;
  className?: string;
};

function DateField(
  props: AriaDatePickerProps<DateValue> & AdditionalDateFieldProps
) {
  const ref = useRef<HTMLDivElement | null>(null);

  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });
  const { fieldProps } = useDateField(props, state, ref);
  return (
    <div
      className={cn(
        'flex h-10 w-full items-center justify-between rounded-l-md border border-r-0 border-input shadow-input',
        props.className
      )}
    >
      <div
        {...fieldProps}
        ref={ref}
        className={cn(
          'inline-flex w-full flex-1 items-center bg-transparent py-2 pl-3 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          props.isDisabled ? 'cursor-not-allowed opacity-50' : ''
        )}
      >
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}
        {state.isInvalid && <span aria-hidden='true'>🚫</span>}
      </div>
      {state.value && !props.hideResetButton && !props.isDisabled && (
        <Button
          // @ts-expect-error Should be fixed in react-aria
          onClick={() => state.setValue(null)}
          type='button'
          variant={'ghost'}
          size={'icon'}
          className='mr-2'
        >
          <XMarkIcon className='size-4' />
          <span className='sr-only'>Rensa</span>
        </Button>
      )}
    </div>
  );
}

export { DateField };
