'use client';

import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';
import {
  CalendarDate,
  isToday as _isToday,
  createCalendar,
  getLocalTimeZone,
  startOfMonth,
  today,
} from '@internationalized/date';
import {
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import type { AriaButtonProps, CalendarProps, DateValue } from 'react-aria';
import {
  mergeProps,
  useButton,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useDateFormatter,
  useFocusRing,
  useLocale,
} from 'react-aria';
import type {
  CalendarState,
  DatePickerState,
  RangeCalendarState,
} from 'react-stately';
import { useCalendarState } from 'react-stately';
import { TimeField } from './time-field';

const WEEKS_IN_MONTH = 6;

const presets: Record<number, { title: string; value: () => DateValue }> = {
  1: {
    title: 'Idag',
    value: () => today(getLocalTimeZone()),
  },
  2: {
    title: 'Imorgon',
    value: () => today(getLocalTimeZone()).add({ days: 1 }),
  },
  3: {
    title: 'Sista nuvarande månad',
    value: () =>
      startOfMonth(today(getLocalTimeZone())).add({ months: 1, days: -1 }),
  },
  4: {
    title: 'Första i kommande månad',
    value: () => startOfMonth(today(getLocalTimeZone())).add({ months: 1 }),
  },
  5: {
    title: 'Sista i kommande månad',
    value: () =>
      startOfMonth(today(getLocalTimeZone())).add({ months: 2, days: -1 }),
  },
};

function Calendar(
  props: CalendarProps<DateValue> & { datePickerState: DatePickerState }
) {
  const prevButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);
  const [key, setKey] = useState<string>(crypto.randomUUID());
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });
  const {
    calendarProps,
    prevButtonProps: _prevButtonProps,
    nextButtonProps: _nextButtonProps,
  } = useCalendar(props, state);
  const { buttonProps: prevButtonProps } = useButton(
    _prevButtonProps,
    prevButtonRef
  );
  const { buttonProps: nextButtonProps } = useButton(
    _nextButtonProps,
    nextButtonRef
  );

  const onChange = (e: string) => {
    const value = Number(e);
    const preset = presets[value];
    const date = state.focusedDate.set(preset.value());
    state.setFocusedDate(date);
    state.setValue(date);
  };

  const isDropdownValueDisabled = (value: DateValue) => {
    const toCalendarDate = new CalendarDate(value.year, value.month, value.day);
    return state.isCellUnavailable(toCalendarDate);
  };

  return (
    <div {...calendarProps} className='space-y-2'>
      <div className='flex items-center justify-between gap-2 pt-1'>
        <div className='flex flex-1 items-center gap-2'>
          <MonthDropdown state={state} />
          <YearDropdown state={state} />
        </div>
        <Button
          {...prevButtonProps}
          onClick={() => {
            setKey(crypto.randomUUID());
          }}
          ref={prevButtonRef}
          variant={'ghost'}
          size={'icon-sm'}
          className={'rounded-full'}
        >
          <ChevronLeftIcon className='size-4' />
        </Button>

        <Button
          {...nextButtonProps}
          onClick={() => {
            setKey(crypto.randomUUID());
          }}
          ref={nextButtonRef}
          variant={'ghost'}
          size={'icon-sm'}
          className={'rounded-full'}
        >
          <ChevronRightIcon className='size-4' />
        </Button>
      </div>
      <CalendarGrid state={state} setKey={setKey} />
      <div className='flex gap-2'>
        {!!props.datePickerState.hasTime && (
          <TimeField
            value={props.datePickerState.timeValue}
            onChange={props.datePickerState.setTimeValue}
            shouldForceLeadingZeros={true}
          />
        )}
        <Select key={key} onValueChange={onChange}>
          <SelectTrigger className='h-9 min-h-9'>
            <SelectValue placeholder='Välj datum' />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(presets).map(([key, value]) => (
              <SelectItem
                disabled={isDropdownValueDisabled(value.value())}
                key={key}
                value={key}
              >
                {value.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

interface CalendarGridProps {
  state: CalendarState;
  setKey: Dispatch<SetStateAction<string>>;
}

function CalendarGrid({ state, setKey, ...props }: CalendarGridProps) {
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  return (
    <table
      {...gridProps}
      className={cn(gridProps.className, 'w-full border-collapse space-y-1')}
    >
      <thead {...headerProps}>
        <tr className='flex'>
          {weekDays.map((day, index) => (
            <th
              className='w-9 rounded-md text-[0.8rem] font-normal text-muted-foreground'
              key={index}
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(WEEKS_IN_MONTH).keys()].map((weekIndex) => (
          <tr className={cn('mt-2 flex w-full')} key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    setKey={setKey}
                  />
                ) : (
                  <td key={i} />
                )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
interface RangeCalendarGridProps {
  state: RangeCalendarState;
  setKey: Dispatch<SetStateAction<string>>;
}

function RangeCalendarGrid({
  state,
  setKey,
  ...props
}: RangeCalendarGridProps) {
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  return (
    <table
      {...gridProps}
      className={cn(gridProps.className, 'w-full border-collapse space-y-1')}
    >
      <thead {...headerProps}>
        <tr className='flex'>
          {weekDays.map((day, index) => (
            <th
              className='w-9 rounded-md text-[0.8rem] font-normal text-muted-foreground'
              key={index}
            >
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(WEEKS_IN_MONTH).keys()].map((weekIndex) => (
          <tr className={cn('mt-2 flex w-full')} key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? (
                  <RangeCalendarCell
                    key={i}
                    state={state}
                    date={date}
                    setKey={setKey}
                  />
                ) : (
                  <td key={i} />
                )
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

interface CalendarCellProps {
  state: CalendarState;
  date: CalendarDate;
  setKey: Dispatch<SetStateAction<string>>;
}

function CalendarCell({ state, date, setKey }: CalendarCellProps) {
  const ref = useRef<HTMLButtonElement | null>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
    isUnavailable,
  } = useCalendarCell({ date }, state, ref);

  const isToday = useMemo(() => {
    const timezone = getLocalTimeZone();
    return _isToday(date, timezone);
  }, [date]);

  return (
    <td
      {...cellProps}
      className={cn(
        cellProps.className,
        'relative p-0 text-center text-sm focus-within:relative focus-within:z-20'
      )}
    >
      <Button
        {...buttonProps}
        onClick={() => {
          setKey(crypto.randomUUID());
        }}
        type='button'
        variant={'ghost'}
        ref={ref}
        className={cn(
          buttonProps.className,
          'size-9 rounded-full',
          isToday ? 'bg-accent text-accent-foreground' : '',
          isSelected
            ? 'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground'
            : '',
          isOutsideVisibleRange ? 'text-muted-foreground opacity-50' : '',
          isDisabled || isUnavailable
            ? 'pointer-events-none text-muted-foreground opacity-50'
            : ''
        )}
      >
        {formattedDate}
      </Button>
    </td>
  );
}

interface RangeCalendarCellProps {
  state: RangeCalendarState;
  date: CalendarDate;
  setKey: Dispatch<SetStateAction<string>>;
}

function RangeCalendarCell({ state, date, setKey }: RangeCalendarCellProps) {
  const ref = useRef<HTMLButtonElement | null>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  const isToday = useMemo(() => {
    const timezone = getLocalTimeZone();
    return _isToday(date, timezone);
  }, [date]);

  return (
    <td
      {...cellProps}
      className={cn(
        cellProps.className,
        'relative p-0 text-center text-sm focus-within:relative focus-within:z-20',
        state.highlightedRange?.start.compare(date) === 0 && isSelected
          ? 'rounded-l-full'
          : '',
        state.highlightedRange?.end.compare(date) === 0 && isSelected
          ? 'rounded-r-full bg-primary text-primary-foreground'
          : '',
        isSelected
          ? 'bg-accent text-accent-foreground first:rounded-l-full last:rounded-r-full'
          : ''
      )}
    >
      <Button
        {...buttonProps}
        onClick={() => {
          setKey(crypto.randomUUID());
        }}
        type='button'
        variant={'ghost'}
        ref={ref}
        className={cn(
          'rounded-full p-2 transition-colors hover:bg-primary/10',
          state.highlightedRange?.start.compare(date) === 0 && isSelected
            ? 'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground'
            : '',
          state.highlightedRange?.end.compare(date) === 0 && isSelected
            ? 'rounded-full bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground'
            : '',
          buttonProps.className,
          'size-9',
          isToday ? 'bg-accent text-accent-foreground' : '',
          isToday &&
            isSelected &&
            (state.highlightedRange?.start.compare(date) === 0 ||
              state.highlightedRange?.end.compare(date) === 0)
            ? 'bg-primary text-primary-foreground'
            : '',
          isOutsideVisibleRange ? 'text-muted-foreground opacity-50' : '',
          isDisabled
            ? 'pointer-events-none text-muted-foreground opacity-50'
            : ''
        )}
      >
        {formattedDate}
      </Button>
    </td>
  );
}

function MonthDropdown({ state }: { state: CalendarState }) {
  const months = [];
  const formatter = useDateFormatter({
    month: 'short',
    timeZone: state.timeZone,
  });

  // Format the name of each month in the year according to the
  // current locale and calendar system. Note that in some calendar
  // systems, such as the Hebrew, the number of months may differ
  // between years.
  const numMonths = state.focusedDate.calendar.getMonthsInYear(
    state.focusedDate
  );
  for (let i = 1; i <= numMonths; i++) {
    const date = state.focusedDate.set({ month: i });
    months.push(formatter.format(date.toDate(state.timeZone)));
  }

  const onChange = (e: string) => {
    const value = Number(e);
    const date = state.focusedDate.set({ month: value, day: 1 });
    state.setFocusedDate(date);
    state.setValue(date);
  };

  return (
    <Select onValueChange={onChange} value={state.focusedDate.month.toString()}>
      <SelectTrigger className='h-9 min-h-9' aria-label='Month'>
        <SelectValue placeholder={months[state.focusedDate.month - 1]} />
      </SelectTrigger>
      <SelectContent>
        {months.map((month, i) => (
          <SelectItem
            onSelect={(e) => e.stopPropagation()}
            key={i}
            value={(i + 1).toString()}
          >
            {month}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
function RangeMonthDropdown({ state }: { state: RangeCalendarState }) {
  const months = [];
  const formatter = useDateFormatter({
    month: 'short',
    timeZone: state.timeZone,
  });

  // Format the name of each month in the year according to the
  // current locale and calendar system. Note that in some calendar
  // systems, such as the Hebrew, the number of months may differ
  // between years.
  const numMonths = state.focusedDate.calendar.getMonthsInYear(
    state.focusedDate
  );
  for (let i = 1; i <= numMonths; i++) {
    const date = state.focusedDate.set({ month: i });
    months.push(formatter.format(date.toDate(state.timeZone)));
  }

  const onChange = (e: string) => {
    const value = Number(e);
    const date = state.focusedDate.set({ month: value, day: 1 });
    state.setFocusedDate(date);
  };

  return (
    <Select onValueChange={onChange} value={state.focusedDate.month.toString()}>
      <SelectTrigger className='h-9 min-h-9' aria-label='Month'>
        <SelectValue placeholder={months[state.focusedDate.month - 1]} />
      </SelectTrigger>
      <SelectContent>
        {months.map((month, i) => (
          <SelectItem
            onSelect={(e) => e.stopPropagation()}
            key={i}
            value={(i + 1).toString()}
          >
            {month}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

function YearDropdown({ state }: { state: CalendarState }) {
  const years: { value: CalendarDate; formatted: string }[] = [];
  const formatter = useDateFormatter({
    year: 'numeric',
    timeZone: state.timeZone,
  });

  // Format 100 years on each side of the current year according
  // to the current locale and calendar system.
  for (let i = -100; i <= 100; i++) {
    const date = state.focusedDate.add({ years: i });
    years.push({
      value: date,
      formatted: formatter.format(date.toDate(state.timeZone)),
    });
  }

  const onChange = (e: string) => {
    const index = parseInt(e);
    const date = years[index].value;
    state.setFocusedDate(date);
    state.setValue(date);
  };

  return (
    <Select value={'100'} onValueChange={onChange}>
      <SelectTrigger className='h-9 min-h-9' aria-label='Year'>
        <SelectValue placeholder={state.focusedDate.year} />
      </SelectTrigger>
      <SelectContent className='max-h-96 overflow-auto'>
        {years.map((year, i) => (
          <SelectItem
            onSelect={(e) => e.preventDefault()}
            key={i}
            value={i.toString()}
          >
            {year.formatted}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
function RangeYearDropdown({ state }: { state: RangeCalendarState }) {
  const years: { value: CalendarDate; formatted: string }[] = [];
  const formatter = useDateFormatter({
    year: 'numeric',
    timeZone: state.timeZone,
  });

  // Format 100 years on each side of the current year according
  // to the current locale and calendar system.
  for (let i = -100; i <= 100; i++) {
    const date = state.focusedDate.add({ years: i });
    years.push({
      value: date,
      formatted: formatter.format(date.toDate(state.timeZone)),
    });
  }

  const onChange = (e: string) => {
    const index = parseInt(e);
    const date = years[index].value;
    state.setFocusedDate(date);
  };

  return (
    <Select value={'100'} onValueChange={onChange}>
      <SelectTrigger className='h-9 min-h-9' aria-label='Year'>
        <SelectValue placeholder={state.focusedDate.year} />
      </SelectTrigger>
      <SelectContent className='max-h-96 overflow-auto'>
        {years.map((year, i) => (
          <SelectItem
            onSelect={(e) => e.preventDefault()}
            key={i}
            value={i.toString()}
          >
            {year.formatted}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

export function CalendarButton(props: AriaButtonProps<'button'>) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <Button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      variant={'ghost'}
      size={'icon-sm'}
      className={`rounded-full ${isFocusVisible ? 'ring-2 ring-primary ring-offset-2' : ''}`}
    >
      {props.children}
    </Button>
  );
}

export function FieldButton(
  props: AriaButtonProps<'button'> & { isPressed: boolean }
) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(props, ref);
  return (
    <button
      {...buttonProps}
      ref={ref}
      className={`-ml-px rounded-r-md border px-2 outline-none transition-colors group-focus-within:border-violet-600 group-focus-within:group-hover:border-violet-600 ${
        isPressed
          ? 'border-gray-400 bg-gray-200'
          : 'border-gray-300 bg-gray-50 group-hover:border-gray-400'
      }`}
    >
      {props.children}
    </button>
  );
}

export {
  Calendar,
  CalendarCell,
  CalendarGrid,
  MonthDropdown,
  RangeCalendarCell,
  RangeCalendarGrid,
  RangeMonthDropdown,
  RangeYearDropdown,
  YearDropdown,
};
