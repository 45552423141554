'use client';

import { memo, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { cn } from '@/lib/utils';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/16/solid';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import type { File } from 'react-pdf/dist/esm/shared/types';
import useResizeObserver from 'use-resize-observer';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import OverlayLoader from '../ui/overlay-loader';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const SimplePDFDisplay = memo(
  ({
    file,
    containerClassName,
    url,
    docContainerClassName,
    paginationContainerClassName,
  }: {
    file?: File | undefined;
    url?: string;
    containerClassName?: string;
    docContainerClassName?: string;
    paginationContainerClassName?: string;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- TODO: Remove this line
    const [pageAmount, setPageAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loaded, setLoaded] = useState(false);

    const { ref: containerRef, width = 1 } =
      useResizeObserver<HTMLDivElement>();

    function onDocumentLoadSuccess({
      numPages: nextNumPages,
    }: {
      numPages: number;
    }): void {
      setLoaded(true);
      setPageAmount(nextNumPages);
    }

    const memoizedFile = useMemo(() => {
      if (file) return file;

      if (url) {
        return { url };
      }
    }, [file, url]);

    if (!memoizedFile) return;

    return (
      <div
        className={cn('relative w-full', containerClassName)}
        ref={containerRef}
      >
        <div
          className={cn(
            'absolute bottom-1 right-1 z-50 flex items-center space-x-2',
            paginationContainerClassName
          )}
        >
          <Button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={pageAmount < 2 || currentPage === 1}
            variant='outline'
            className='bg-white'
            size='icon-sm'
          >
            <ChevronLeftIcon className='size-4' />
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='outline' className='bg-white'>
                Sida {currentPage} av {pageAmount}{' '}
                <ChevronDownIcon className='ml-1 size-4' />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent>
              {[...Array(pageAmount).keys()].map((i) => (
                <DropdownMenuItem key={i} onClick={() => setCurrentPage(i + 1)}>
                  Sida {i + 1}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={pageAmount < 2 || currentPage == pageAmount}
            variant='outline'
            className='bg-white'
            size='icon-sm'
          >
            <ChevronRightIcon className='size-4' />
          </Button>
        </div>

        <div className={cn(docContainerClassName)}>
          <Document
            file={memoizedFile}
            noData={() => (
              <div className='relative flex size-full items-center justify-center text-sm'>
                Ingen fil att visa
              </div>
            )}
            onLoadError={(err) => {
              console.log(err);
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={() => (
              <div className='relative size-full'>
                <OverlayLoader />
              </div>
            )}
          >
            {loaded && (
              <Page
                width={width}
                // height={height}
                pageNumber={currentPage}
                renderForms={false}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                loading={() => (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '150px',
                    }}
                  >
                    Laddar dokument...
                  </div>
                )}
              />
            )}
          </Document>
        </div>
      </div>
    );
  }
);

SimplePDFDisplay.displayName = 'SimplePDFDisplay';
export default SimplePDFDisplay;
