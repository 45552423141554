import { cn } from '@/lib/utils';
import { Icons } from '../icons';
interface LoaderProps {
  className?: string;
  message?: string;
}

export default function OverlayLoader({
  className,
  message = 'Laddar...',
}: LoaderProps) {
  return (
    <>
      <div
        className={cn(
          'absolute inset-0 z-50 flex flex-col items-center justify-center  gap-4 rounded bg-background/30 backdrop-blur-sm',
          className
        )}
      />
      <div
        className={cn(
          'absolute inset-0 z-50 flex flex-col items-center  justify-center gap-4 ',
          className
        )}
      >
        <Icons.loader
          className='m-0 animate-spin text-foreground'
          width={34}
          height={34}
        />
        <p className='font-medium'>{message}</p>
      </div>
    </>
  );
}
